/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

//import Headroom from 'headroom.js';
import focusLock from 'dom-focus-lock';

$(document).ready(async function () {

	// Responsive menu
	var responsiveNav = $('.responsive-nav');
	$('header').each(function () {
		var element = $(this),
			body = $(document.body);

		var toggler = $('.toggler').click(function () {
			//body.toggleClass('open');
			responsiveNav.toggleClass('-collapsed');
			toggler.toggleClass('-closed');
		});
	});

	responsiveNav.find('nav > ul > li').each(function () {
		var element = $(this);
		var link = element.children('a').first();
		link.click(function (e) {
			responsiveNav.toggleClass('-collapsed');
			toggler.toggleClass('-closed');
			e.preventDefault();
		});
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-height').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.match-height')[0]) {
		matchHeights();
	}

	// Slideshow
	$('.slideshow').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: false,
			dots: false,
			fade: true,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000
		});
	});

	// Gallery
	$('.gallery').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: false,
			dots: false,
			fade: true,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000
		});
	});

	// slider
	$('.slider').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: true,
			dots: false,
			fade: false,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000,
			slidesToShow: 2,
			responsive: [
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});
	});

	$('.slider-blog').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: true,
			dots: false,
			fade: false,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		//await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	//// Close modal-wrapper on backdrop click or escape key
	//const modalWrapper = $('.modal'), modal = $('.modal-card'), modalToggler = $('modal-checkbox');
	//if (modalToggler && modalWrapper && modal) {
	//	var closeToggle = $('.modal-close');
	//	const toggle = checked => {
	//		modalToggler.checked = checked;
	//		modalWrapper.attr('aria-expanded', checked);

	//		if (checked) {
	//			focusLock.on(modal);

	//			// Focus close toggle on open
	//			if (closeToggle) {
	//				closeToggle.focus();
	//			}
	//		} else {
	//			focusLock.off(modal);
	//		}
	//	};

	//	//modalWrapper.click(e => {
	//	//	if (!modal.contains(e.target)) {
	//	//		toggle(false);
	//	//	}
	//	//});

	//	$(document).on('keydown', function (e) {
	//		if (e.keyCode === 27 && modalToggler.checked) {
	//			console.log("KOMT HIER");
	//			toggle(false);
	//		}
	//	});

	//	// Keep aria-expanded in-sync with checked state
	//	modalToggler.change(() => {
	//		toggle(modalToggler.checked);
	//	});

	//	toggle(modalToggler.checked);
	//}
});